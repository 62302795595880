import React, { Component } from 'react';
import { HashRouter, Route, Switch ,Redirect  } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import { withRouter } from 'react-router';
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {
  constructor(prevProps,props) {
    super(prevProps,props); 
  }
  componentDidMount(){
    let token=localStorage.getItem("Rjstoken")
    if(!token){
      //this.props.histroy.push("/#/admin/login");
      window.location = '/#/admin/login';
    }
  }
  render() {
    return (
      <HashRouter basename="/admin">
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
           {/* <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/dashboard" name="Home" component={DefaultLayout} /> 
          <Route  path="/" name="Login Page" component={Login} /> */}
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
